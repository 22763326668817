.dsu-main-content { width: 100%; position: relative;}
.bannerBox { background-image: url(../../public/assests/images/banner.jpg); height: 510px; }
.parallax { position: relative; min-height: 100%; background-size: cover; background-repeat: no-repeat; background-position: center top; z-index: 1;}
.dsu-form { width: 308px; float: left; padding: 10px 0 0; margin-top: 0px; background: rgba(255,204,41,0.5); height: 506px;}
.frmBg { text-align: center;  position: relative;}
.frmBg small { font-size: 14px; color: #FFFFFF; display: block; margin-bottom: 5px;}
.frmBg span { font-size: 26px; color: #FFFFFF; display: block;}
.applyBox {
    width: 320px;
    display: table;
    float: left;
    
}
/*Add*/
.elg-rgst{list-style:none;}
.elg-rgst li{color:#fff; font-size:16px; padding-bottom:13px; padding-top:13px; border-bottom:1px solid #FFF; text-align:center;}
/*Add*/

.contact_fild { padding: 20px 8px 10px;}
.contact-fild-box .form-control { font-size: 13px;
   
    color: #000;
    border: 0px;
    border-radius: 20px;
    background-color: #e7e7e7;
   }
.form-group {
    margin-bottom: 0px;
}
.btn_submit { background: #e5ec1c;  color: #000; padding: 6px 35px; font-size: 20px; position: relative; border-radius: 30px;
    margin: 10px 0;}

.btn_submit:hover {background: #459262; color: #FFF;}


.contact-fild-box { margin-bottom: 26px; position: relative;}
.contact-fild-box.text-center { margin-bottom: 0px;}
.form-control { height: 40px;}
.cd-intro {
  
}

.dsu-intro {
    position: absolute;
    width: 100%;
    top: 0px;
    z-index: 1;
}


.dsu-addmission {margin-top: 45%; display: inline-block; padding: 15px;}
.dsu-addmission h1 { position: relative; color: #FFFFFF; padding-left: 15px; text-transform: uppercase; font-size: 30px; line-height: 40px; font-weight: 300; }
.dsu-addmission h1 strong { color: #FFFFFF; display: block; font-size: 27px; }
.dsu-addmission h1:after { position: absolute; content: ""; left: 0px; top: 7px; width: 8px; height: 87%; background-color: #e5ec1c; }
.userfa {
    position: absolute;
    left: 11px;
    top: 8px;
    color: #FFF;
        position: absolute;
    width: 4em;
    height: 100%;
}

 
   
.formIDfid { padding: 0 10px; }
.leftsec_content { position: relative; }

@media screen and (max-width: 600px) {
    .dsu-form{
        
        width: 400px;
        margin-top: 400px;
    }
    
}