body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* ScrollUpButton.css */
.scroll-up-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  background-color: #007bff; 
  color: #fff; 
  border: none;
  padding: 10px 20px;
  border-radius: 14px;
  cursor: pointer;
  z-index: 9999;
}

.scroll-up-button.show {
  display: block;
}

.scroll-up-button:hover {
  background-color: 	#880808 ;
}

/* WhatsAppButton.css */
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  left: 20px; /* Update to left */
  display: flex;
  align-items: center;
  background-color: #25d366;
  color: #ffffff;
  padding: 10px 10px;
  border: none;
  border-radius: 14px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.whatsapp-button:hover {
  background-color: green;
}

.whatsapp-icon {
  margin-right: 8px;
  font-size: 16px;
}









