.trending-courses {
    text-align: center;
    margin: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

.course-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* Each column will take equal width */
    gap: 20px;
    /* Gap between the grid items */
    margin-bottom: 30px;
    /* Add space between the second row and footer */
}

@media screen and (max-width: 450px){
    .course-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        /* Each column will take equal width */
        gap: 20px;
        /* Gap between the grid items */
        margin: 30px;
        /* Add space between the second row and footer */
    }
}

.course-card {
    background-color: #c7e1ef;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(46, 142, 177, 0.1);
    height: 100%;
    /* Ensure equal height for all cards */
}

.course-card img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
}

.course-card h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.course-card p {
    font-size: 1rem;
    margin-bottom: 15px;
}

.course-card a {
    display: block;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.course-card a:hover {
    background-color: #0056b3;
}

.footer {
    background-color: #f0f0f0;
    padding: 20px;
    text-align: center;
}