.card_student_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.container1 {
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 400px; /* You can adjust the width as needed */
   
}

.detail-label {
    font-weight: bold;
    margin-right: 5px;
}


button {
    padding: 10px 20px;
    background-color: #223e9c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}



  
  
  
  .card {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .button-container button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  
  .card {
    width: 400px;
    height: fit-content;
    margin: 5px;
    background: white;
    border-radius: 10px;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
   
   }
   
   .shadow {
    box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
                0 0  0 2px rgb(190, 190, 190),
                0.3em 0.3em 1em rgba(0,0,0,0.3);
   }
   .image{
    width: 150px;
    height: 130px;
    border-radius: 50%;
   }
   
   .search-container {
    position: relative;
    display: inline-block;
    margin-left: 20px; /* Adjust the left margin as needed */
    margin-top: -10px; /* Adjust the top margin as needed */
  }
  
  .search-input {
    padding: 10px;
    width: 200px; /* Adjust the width as needed */
    border: 2px solid #ddd;
    border-radius: 10px; /* Adjust border radius for rounded corners */
    outline: none;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  .search-input:focus {
    border-color: #007bff; /* Change border color on focus */
  }
  
  .search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #777;
  }
  
   